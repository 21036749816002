import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Breadcrumbsecond } from '../../../components';
import Loader from '../../../components/Loader/loader';
import { TimeZone } from '../../../components/TimeZone/TimeZone';
import { getApi } from '../../service/employService';
import PaginationComponent from '../../../PaginationComponent';

const BnplTransactionByID = () => {
    const [search, setSearch] = useState('');
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [customer_id, setCustomer_id] = useState([]);
    const exportfix = process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX
    const employer = JSON.parse(localStorage.getItem("employer"));
    const { id } = useParams()
    const [loader, setloader] = useState(true)
    const [sortedBy, setSortedBy] = useState("customer_id")
    const [orderBy, setOrderBy] = useState('desc')
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const navigate = useNavigate();
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    // const employer = JSON.parse(localStorage.getItem("employer"));

    // console.log(employer, "______________________________")
    const SettlementBYidDetail = async (token) => {
        try {
            const response = await getApi(`/bnpl-employee-all-txn-detail/${id}`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });

            console.log("🚀 ~ file: Merchant.js:124 ~ getDetail ~ response:", response)
            if (response.data) {
                setTimeout(() => {
                    setDataArray(response.data);
                    setTotalPagesCount(response.allEmployeeCount)
                    setCustomer_id(response.data[0].customer_id)
                    setloader(false)
                }, 2000);
                setloader(true)
            } else {
                console.log("get merchant data response", response);
            }
        } catch (error) {
            if (error.message === 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
            console.log(error)
        }
    }

    useEffect(() => {
        SettlementBYidDetail(employer.employer_token)
    }, [search, pagesCount, sortedBy, orderBy])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }


    }
    const copiedInfo = () => {
        toast.success("Copied")
    }


    const [location, setLocation] = useState(null);

    useEffect(() => {
        const successHandler = (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
        };

        const errorHandler = (error) => {
            console.error('Error getting location:', error.message);
        };

        // Check if the Geolocation API is available in the browser
        if ('geolocation' in navigator) {
            // Get the current location
            navigator.geolocation.getCurrentPosition(successHandler, errorHandler);
        } else {
            console.error('Geolocation is not supported by your browser');
        }
    }, []);

    // console.log(location, "__________________________-")

    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header my-3 py-4 pb-0">
                <div className="page-leftheader">
                    <Breadcrumbsecond order={{ first: { name: "BNPL Repayments", path: `${prefix}/bnpl_repayment` }, second: { name: "Transaction Details" } }} />
                </div>
                <div style={{ marginLeft: '450px', marginTop: '25px' }}>
                </div>

                <div className="page-rightheader">
                    <div className="btn-list">
                        <a href={exportfix + `/export-bnpl-employee-all-txn-detail/${employer.user_name}/${customer_id}?time_zone=${Timezone}`} className="btn btn-outline-primary text-primary" download ><i className="fa fa-download me-2"></i> Export Excel</a>
                    </div>
                </div>

            </div>
            <div className="row">
                {/*<div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
             <div className="form-group">
                <label className="form-label">Loan Type</label>
                <div className="row g-xs">
                    <div className="wd-200 mg-b-30">
                        <div className="input-group">
                            <select className="form-control mb-0" name="loan_type" value={queryFilter.loan_type} onChange={(e) => handleChangeQueryBuilder(e)}>
                                <option>Select Loan</option>
                                <option value="1">BNPL</option>
                                <option value="2">Digital Lending</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div> 
        </div>*/}
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                    <div className="form-group">
                        <label className="form-label">Search</label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row">
        <div className="col-sm-12 col-lg-12">
            <div className="card">
                <div className="card-header br-0">
                    <h3 className="card-title">EMPLOYEE REPORT</h3>
                </div>
            </div>
        </div>
    </div> */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table table-hover card-table table-vcenter text-nowrap" >
                                    <thead className="border-bottom-0 pt-3 pb-3">
                                        <tr>
                                            <th className="font-weight-bold">Customer Detail {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('first_name') }}></i>} </th>
                                            <th className="font-weight-bold">Employee Code {sortedBy == "employee_code" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('employee_code') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('employee_code') }}></i>} </th>
                                            <th className="font-weight-bold">Deduction Code {sortedBy == "deduction_code" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('deduction_code') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('deduction_code') }}></i>}</th>
                                            <th className="font-weight-bold">Repayment Amount  {sortedBy == "emi_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('emi_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('emi_amount') }}></i>}</th>
                                        </tr>
                                    </thead>
                                    {loader == true ? <tbody>
                                        <td /><td />
                                        <div  >
                                            <Loader /> </div> </tbody> :
                                        <>
                                            <tbody>
                                                {dataArray.length > 0 && dataArray.map((data, i) => {
                                                    let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                                    const { first_name, last_name, mobile_number, emi_amount, employee_code, deduction_code } = data;
                                                    // var timezone = new Date(data.updated_at).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true });

                                                    return (
                                                        <tr key={serial_num}>

                                                            <td>{first_name ? first_name : <span>--</span>} {last_name}<br /> {mobile_number}</td>
                                                            <td>{employee_code ? employee_code : "--"}</td>
                                                            <td>{deduction_code ? deduction_code : "--"}</td>
                                                            <td>{emi_amount ? "$" + emi_amount : "NA"}</td>
                                                            {/* <td>{TimeZone(updated_at).timeZone}</td> */}
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                            <tbody>
                                                {dataArray.length == 0 &&
                                                    <tr>
                                                        <td colSpan={10} className='text-center'>No Data Available</td>
                                                    </tr>
                                                }
                                            </tbody></>}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {pagesCount > 0 && dataArray.length > 0 ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}

            </div>



        </div>
    )
}

export default BnplTransactionByID

