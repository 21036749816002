import React, { useEffect, useRef, useState } from 'react'
import { getApi } from '../../service/lenderService';
import { Link, useNavigate } from 'react-router-dom';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';
import Loader from '../../../components/Loader/loader';
import { Image } from 'antd';
import PaginationComponent from '../../../PaginationComponent';
import moment from 'moment';
import { TimeZone } from '../../../components/TimeZone/TimeZone';
import Search from '../../../components/Search/Search';

const Emitransaction = () => {
    const [lenderData, setDsaData] = useState(localStorage.getItem("lender"));
    const [token, setToken] = useState(JSON.parse(lenderData)?.lender_token);
    const [dataArray, setDataArray] = useState([]);
    const tableRef = useRef(null);
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const [loader, setloader] = useState(true)
    const [copied, setCopied] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [sortedBy, setSortedBy] = useState("id")
    const [orderBy, setOrderBy] = useState('desc')
    const [ValidPagination, setValidPagination] = useState(false)

    const prefix = process.env.REACT_APP_LENDER_PRIFIX;

    // const copiedInfo = () => {
    //     toast.success("Transaction No. copied Successfully")
    // }

    const AllEmitransaction = async () => {
        // const response = await getAllEmitransaction(token, search, pagesCount, sortedBy, orderBy);
        const response = await getApi(`/all-paid-emi-list`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });

        console.log(response)
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data.rows)
                setTotalPagesCount(response.data.count)
                console.log(response)
                setloader(false)
            }, 2000);
            setloader(true)
        } else if (response.message == 'Session expired') {
            localStorage.removeItem('lender');
            navigate(process.env.REACT_APP_LENDER_PRIFIX + '/login')
        } else if (response.message == "Access denied! you're not allowed to access this tab") {
            toast.error(response.message)
            navigate('/lender/dashboard')
        }
        else {
            console.log(response)
        }
    }

    useEffect(() => {
        AllEmitransaction(token)
    }, [search, pagesCount, sortedBy, orderBy, ValidPagination])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("asc")
        }
        AllEmitransaction(token)
    }

    return (
        <div>
            <div className="main-container container-fluid px-0">
                <div className="page-header mb-3">
                    <div className="page-leftheader">
                        {/* <h4 className="page-title mb-0 text-primary">Paid</h4> */}

                        <h4 className="page-title mb-0 text-primary ">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a>Repayments</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Paid</li>
                                </ol>
                            </nav>
                        </h4>

                        {/* {checkbox  ? <button className="btn btn-primary" onClick={updatesynch}>Sync</button>:
                 <button className="btn btn-primary" disabled>Sync</button>} */}
                    </div>
                    {/* <div style={{ marginLeft: '360px' , marginTop: '10px' }}>
            <h5 > {TotalCount ? `Total: $${TotalCount}` : ""}</h5></div>
            <h5 > <b>{num3 ? `Interest:  $${num3}` : ""}</b></h5> */}
                    <div className="page-rightheader">


                        {/* <h6 className="ml-2"> Total Interest : $5647</h6> */}
                        <div className="btn-list">
                            <DownloadTableExcel
                                filename="users table"
                                sheet="users"
                                currentTableRef={tableRef.current}>
                                <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                            </DownloadTableExcel>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">

                        {/* <div className="form-group">
                    <label className="form-label">All Status</label>
                    <div className="row g-xs">
                        <div className="wd-200 mg-b-30">
                            <div className="input-group">
                                <select className="form-control mb-0" name='submit'   value={setQueryFilter.filter}  onChange={(e) => handleChangeQueryBuilder(e.target.value)} >
                                    <option>Select Status</option>
                                    <option value="all task" >All Status</option>
                                    <option value="Assigned"  >Assigned</option>
                                    <option value="Unassigned" >Unassigned</option>

                                </select>
                            </div>
                        </div>
                    </div>
                </div> */}
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                    </div>
                    <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

                    {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Search</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                    <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive">
                                    <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                        <thead className="border-bottom-0 pt-3 pb-3">
                                            <tr>
                                                <th className="font-weight-bold">Logo</th>
                                                <th className="font-weight-bold">Customer Details  {sortedBy == "customer_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('customer_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('customer_name') }}> </i>} </th>
                                                <th className="font-weight-bold">Merchant Details  {sortedBy == "merchant_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }}> </i>} </th>
                                                <th className="font-weight-bold">TXN Info  {sortedBy == "txn_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_amount') }}> </i>} </th>
                                                <th className="font-weight-bold">Due Date  {sortedBy == "due_date" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('due_date') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('due_date') }}> </i>} </th>
                                                <th className="font-weight-bold">Late Days/Charges  {sortedBy == "late_days" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('late_days') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('late_days') }}> </i>} </th>
                                                <th className="font-weight-bold">Repayment Amount  {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}> </i>} </th>
                                                <th className="font-weight-bold">Repayment No.  {sortedBy == "emi_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('emi_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('emi_number') }}> </i>} </th>
                                                <th className="font-weight-bold">Lender Commission  {sortedBy == "lender_commission_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('lender_commission_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('lender_commission_amount') }}> </i>} </th>
                                                <th className="font-weight-bold">Notes  {sortedBy == "notes" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('notes') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('notes') }}> </i>} </th>
                                                <th className="font-weight-bold">Paid Date  {sortedBy == "paid_date" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('paid_date') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('paid_date') }}> </i>} </th>
                                                <th className="font-weight-bold"> Status  {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('status') }}> </i>} </th>

                                            </tr>
                                        </thead>
                                        {loader == true ? <tbody>
                                            <td /><td /> <td /><td />
                                            <Loader />
                                        </tbody> :
                                            <>
                                                <tbody>
                                                    {dataArray.length > 0 && dataArray.map((option, index) => {
                                                        // let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                        const { customer_name, emi_docs, customer_mobile_number, due_date, merchant_name, notes, amount, merchant_mobile_number, txn_amount, late_charges, late_days, remaining_amount, txn_id, lender_commission_amount, lender_roi, emi_number, paid_date, status } = option;
                                                        // var num2 = parseFloat(option.updated_at).toFixed(2)
                                                        // var timezone = new Date(option.updated_at).toLocaleString(undefined, {timeZone: 'Asia/Kolkata', hour12:true  });                                                  

                                                        return (
                                                            <tr key={index} >
                                                                {emi_docs != null ?
                                                                    <td><span className="font-weight-normal1">
                                                                        <Image style={{ width: 50, height: 50, borderRadius: '8px' }} src={'https://thirdroc1.s3.ap-southeast-2.amazonaws.com/emidocs/' + emi_docs} alt='...' /></span></td>
                                                                    :
                                                                    <td><span className="font-weight-normal1" >

                                                                        <Image style={{ width: 50, height: 50, borderRadius: '8px' }} src={process.env.PUBLIC_URL + '/assets/img/defaultMerchant.png'} alt='...' /></span></td>
                                                                }
                                                                <td><Link className="text-primary" to={{ pathname: `${prefix}/employee_detail/${customer_mobile_number}/Existing`, state: { customer_mobile_number } }}><span className="font-weight-normal1">{option.customer_name ? customer_name : ""}<br />{customer_mobile_number}</span></Link></td>
                                                                <td><span className="font-weight-normal1">{option.merchant_name ? merchant_name : ""}<br />{merchant_mobile_number} </span></td>
                                                                <td><span className="font-weight-normal1"> {option.txn_amount ? "$" + txn_amount : ""}<br />
                                                                    {option.txn_id ? txn_id : ""}
                                                                    {/* <span>
                                                <CopyToClipboard text={txn_id} onCopy={() => setCopied(true)}>
                                                <button className='btn btn-primary btn-sm ms-3'onClick={() => copiedInfo()}><i className="fa-solid fa-copy"></i></button>
                                                </CopyToClipboard>
                                                </span> */}
                                                                </span></td>
                                                                <td><span className="font-weight-normal1"> {option.due_date ? due_date : "NA"} </span></td>
                                                                <td><span className="font-weight-normal1"> {option.late_days ? late_days : "0 Days"}
                                                                    <br />{option.late_charges ? "$" + late_charges : ""} </span></td>
                                                                <td><span className="font-weight-normal1"> {option.amount ? "$" + amount : "NA"} </span></td>
                                                                <td><span className="font-weight-normal1"> {option.emi_number ? emi_number : "NA"} </span></td>
                                                                <td><span className="font-weight-normal1"> {option.lender_commission_amount ? "$" + lender_commission_amount + "(" + lender_roi + '%)' : "NA"} </span></td>
                                                                <td><span className="font-weight-normal1"> {option.notes ? notes : "NA"} </span></td>
                                                                <td><span className="font-weight-normal1"> {option.paid_date ? TimeZone(option?.paid_date).timeZone : "NA"} </span></td>
                                                                <td><span className="font-weight-normal1"> {option.status ? status : "NA"} </span></td>

                                                            </tr>
                                                        )
                                                    }
                                                    )}
                                                </tbody>
                                                <tbody>
                                                    {dataArray.length == 0 &&
                                                        <tr>
                                                            <td colSpan={10} className='text-center'>No Paid Transaction Data Available</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </>}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                        <>
                            <div className="col-md-12">
                                <div className="card-body">
                                    <PaginationComponent className="justify-content-center"
                                        totalItems={totalPagesCount}
                                        pageSize={10}
                                        maxPaginationNumbers={3}
                                        onSelect={(e) => setpagesCount(e)}
                                    />
                                </div>
                            </div>
                        </>
                        : ''}
                    {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                        <>
                            <div className="col-md-12">
                                <div className="card-body">
                                    <PaginationComponent className="justify-content-center"
                                        totalItems={totalPagesCount}
                                        pageSize={10}
                                        maxPaginationNumbers={3}
                                        onSelect={(e) => setpagesCount(e)}
                                    />
                                </div>
                            </div>
                        </>
                        : ''}
                </div>
            </div>
        </div>
    )
}

export default Emitransaction
