import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import toast, { Toaster } from 'react-hot-toast';
import CopyToClipboard from 'react-copy-to-clipboard';
import moment from 'moment';
import { ViewActivityLogs, getApi } from '../../service/lenderService';
import Loader from '../../../components/Loader/loader';
import PaginationComponent from '../../../PaginationComponent';
import { TimeZone } from '../../../components/TimeZone/TimeZone';


const Applicationlogs = () => {
    const [copied, setCopied] = useState(false);
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [lenderData, setDsaData] = useState(localStorage.getItem("lender"));
    const [token, setToken] = useState(JSON.parse(lenderData)?.lender_token);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const prefix = process.env.REACT_APP_LENDER_PRIFIX;
    const [sortedBy, setSortedBy] = useState("user_loan_id")
    const [orderBy, setOrderBy] = useState('desc')
    const [loader, setloader] = useState(true)
    const navigate = useNavigate();
    const { id } = useParams();

    const viewActivityLogs = async (token) => {
        // const response = await ViewActivityLogs(token, id, search, pagesCount, sortedBy, orderBy)
        const response = await getApi(`/get-loan-application-activity/${id}`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy })
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data.rows)
                setTotalPagesCount(response.data.count)

                setloader(false)
            }, 2000);
            setloader(true)
        } else if (response.message == 'Session expired') {
            localStorage.removeItem('lender');
            navigate(process.env.REACT_APP_LENDER_PRIFIX + '/login')
        } else if (response.message == "Access denied! you're not allowed to access this tab") {
            toast.error(response.message)
            navigate('/lender/dashboard')
        } else {
            console.log(response)
        }
    }

    useEffect(() => {
        viewActivityLogs(token, search, pagesCount)

    }, [search, pagesCount, sortedBy, orderBy])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }


    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">EWA Advance </h4>
                            </div>
                            <div className="page-rightheader">
                                <div className="btn-list">
                                    <DownloadTableExcel
                                        filename="users table"
                                        sheet="users"
                                        currentTableRef={tableRef.current}>
                                        <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                                    </DownloadTableExcel>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">

                                {/* <div className="form-group">
                                    <label className="form-label">All Status</label>
                                    <div className="row g-xs">
                                        <div className="wd-200 mg-b-30">
                                            <div className="input-group">
                                                <select className="form-control mb-0" name='submit'  value={setQueryFilter.filter}  onChange={(e) => handleChangeQueryBuilder(e.target.value)}>
                                                    <option>Select Status</option>
                                                    <option value="all task" >All Status</option>
                                                    <option value="Assigned"  >Assigned</option>
                                                    <option value="Unassigned" >Unassigned</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">Updated By  {sortedBy == "id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('id') }}> </i>}</th>
                                                        <th className="font-weight-bold">New Status  {sortedBy == "new_status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('new_status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('new_status') }}> </i>}</th>
                                                        <th className="font-weight-bold">Activity Date  {sortedBy == "sub_lender_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('sub_lender_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('sub_lender_id') }}> </i>}</th>                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody><td /><Loader /></tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { employer_name, new_status } = option;
                                                                // var num2 = parseFloat(option.interest_rate).toFixed(2)
                                                                // var Capitalize = (option) => {
                                                                //     return option.charAt(0).toUpperCase() + option.slice(1);
                                                                //     }
                                                                // var timezone = new Date(option.created_at).toLocaleString(undefined, {timeZone: 'Asia/Kolkata', hour12:true  });  


                                                                return (
                                                                    <tr key={serial_num} >
                                                                        <td><Link className="text-primary" >
                                                                            <span className="font-weight-normal1">{option?.subLenderData?.full_name ? option?.subLenderData?.full_name : ""}</span></Link></td>
                                                                        <td><span className="font-weight-normal1">{option?.new_status ? new_status : ""}</span></td>
                                                                        <td><span className="font-weight-normal1">{TimeZone(option?.created_at).timeZone}</span></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No Data Available</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {pagesCount > 0 && dataArray.length > 0 ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}

                        </div>
                    </div>
                </div>

            }
        </>
    )

}

export default Applicationlogs
